import React from 'react';
import {
    NavigateFunction,
    Params,
    Route,
    Routes,
    useNavigate,
    useParams
} from "react-router-dom";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbSkeleton,
    Button,
    Checkbox,
    Column,
    Grid,
    RadioButton,
    RadioButtonGroup,
    TextInput,
    Tile
} from "@carbon/react";
import { Add, Checkmark, Close, Edit, TrashCan, FolderOpen } from '@carbon/react/icons';
import { AccountV1, IPTAccount } from '../../services/AccountV1';
import { LocationV1 } from '../../services/LocationV1';
import UserV1 from '../../../../services/UserV1';
import Location from '../Location/Location';
import LocationSchedule from '../Location/LocationSchedule';
import { AccountFields } from '../NewAccount/NewAccount';
import NewLocation from '../NewLocation/NewLocation';
import { ModalConfirmDelete } from '../../../../components/ModalConfirmDelete';
import SimpleTable from '../../../../components/SimpleTable';
import ModalBasic from '../../../../components/ModalBasic';
import { clone } from '../../../../util/MiscUtil';
import "./Account.scss";

interface AccountProps {
    navigate: NavigateFunction
    params: Params<string>
}

interface AccountState {
    account?: IPTAccount
    editing: boolean
    addingPrivilege: boolean
    editingPrivilege: boolean
    ownerEmails?: string[]
    addUserStr: string
    confirmDelete?: {
        id: string,
        name: string
    }
    confirmDeleteTxt: string
    selectedTab: number,
    locations?: any[]
}

class AccountCls extends React.Component<AccountProps, AccountState> {
    state: AccountState = {
        confirmDeleteTxt: "",
        editing: false,
        addingPrivilege: false,
        addUserStr: "",
        editingPrivilege: false,
        selectedTab: 0
    }

    componentDidMount() {
        (async () => {
            let account = await AccountV1.getAccount(this.props.params["id"]!);
            let ownerEmails: string[] | undefined = undefined;
            if (account.owners) {
                ownerEmails = await UserV1.idsToEmails(account.owners);
            }
            let locations = await LocationV1.getLocations(account.id);
            this.setState({ account, locations, ownerEmails })
        })();
        this.refresh();
    }

    componentWillUnmount() {
        // document.body.style.backgroundColor = "inherit";
    }

    async refresh() {
        let locations = await LocationV1.getLocations(this.props.params["id"]!);
        this.setState({ locations });
    }

    async update(newAccount: IPTAccount) {
        newAccount.id = this.state.account!.id;
        let account = await AccountV1.updateAccount(newAccount);
        this.setState({ account: account });
    }

    render() {
        let accountId = this.props.params["id"]!;
        // START locationPanel
        let locationPanel = (
            <SimpleTable

                noRecordsText="No locations associated with this account"
                headers={[
                    { key: "name", header: "Location name" },
                    { key: "urlname", header: "URL part" },
                    { key: "actions", header: "Actions" }
                ]}
                actions={<>
                    <Button renderIcon={Add} onClick={() => {
                        this.props.navigate(`/account/home/${accountId}/new_location`)
                    }}>New location</Button></>}
                rows={this.state.locations}
                onClick={(rowId) => {
                    this.props.navigate(`/account/home/${this.state.account?.id}/location/home/${rowId}`);
                }}
                cellMapper={(rowId, cellId, value) => {
                    if (cellId === "actions") {
                        return (<div style={{ marginLeft: "-1rem" }}>
                            <Button
                                kind="ghost"
                                hasIconOnly
                                iconDescription="Edit location"
                                renderIcon={FolderOpen}
                                onClick={async () => {
                                    this.props.navigate(`/account/home/${this.state.account?.id}/location/home/${rowId}`);
                                }}
                            />
                            <Button
                                kind="ghost"
                                hasIconOnly
                                iconDescription="Delete"
                                renderIcon={TrashCan}
                                onClick={async (evt) => {
                                    this.setState({
                                        confirmDelete: {
                                            id: rowId,
                                            name: this.state.locations?.filter(location => location.id === rowId)[0].name!
                                        }
                                    })
                                    evt.stopPropagation();
                                }}
                            />
                        </div>);
                    }
                    return value;
                }}
            />
        );
        // END locationPanel

        let sortedEmails = clone(this.state.ownerEmails)?.map((val, idx) => (
            {
                value: val,
                idx: idx
            }
        ))
        sortedEmails?.sort((a, b) => a.value.localeCompare(b.value));
        return (
            <Routes>
                <Route path="/location/home/:locationId/schedule" element={<LocationSchedule /> } />
                <Route path="/location/home/:locationId" element={<Location />} />
                <Route path="/new_location" element={<NewLocation />} />
                <Route path="/" element={<>
                    <div style={{ marginTop: "3rem" }} />
                    <Grid>
                        <Column sm={4} md={8} lg={16}>
                            {!this.state.account && <BreadcrumbSkeleton />}
                            {this.state.account && <Breadcrumb>
                                <BreadcrumbItem href="/account/accounts">Accounts</BreadcrumbItem>
                                <BreadcrumbItem href={`/account/home/${accountId}`} isCurrentPage>
                                    {this.state.account.name}
                                </BreadcrumbItem>
                            </Breadcrumb>}
                        </Column>
                    </Grid>
                    <main>
                        <div style={{ marginTop: "2rem" }} />
                        <Grid>
                            <Column sm={4} md={4} lg={6}>
                                {this.state.account && <AccountFields
                                    editing={this.state.editing}
                                    initialState={{
                                        name: this.state.account?.name ?? "",
                                        urlname: this.state.account?.urlname ?? "",
                                        phone: this.state.account?.phone ?? ""
                                    }}
                                    submitLabel="Update account"
                                    onSubmit={async (newAccount: IPTAccount) => {
                                        await this.update(newAccount);
                                        this.setState({ account: newAccount });
                                    }}
                                />}
                            </Column>
                            <Column sm={4} md={4} lg={6}>
                                <Tile style={{ minHeight: "100%" }}>
                                    <div style={{ float: "right", marginTop: "-.5rem", marginRight: "-.5rem" }}>
                                        {!this.state.editingPrivilege && <>
                                            {this.state.account?.owners && this.state.account?.owners.length > 0 && 
                                            <Button size="sm" hasIconOnly renderIcon={Edit} iconDescription="Edit owners" kind="ghost" tooltipAlignment="end" onClick={() => {
                                                this.setState({ editingPrivilege: true });
                                            }} />}
                                            <Button size="sm" hasIconOnly renderIcon={Add} iconDescription="Add owners" kind="ghost" tooltipAlignment="end" onClick={() => {
                                                this.setState({ addingPrivilege: true });
                                            }} />
                                        </>}

                                        {this.state.editingPrivilege && <>
                                            <Button size="sm" hasIconOnly renderIcon={Checkmark} iconDescription="Confirm edit" kind="ghost" tooltipAlignment="end" onClick={async () => {
                                                if (this.state.account?.owners) {
                                                    let accountUpdate = clone(this.state.account);
                                                    accountUpdate.owners = [];
                                                    let accountUpdateEmails: string[] = [];
                                                    for (let idx=0; idx<this.state.account.owners.length; ++idx) {
                                                        let checkbox = document.getElementById(`ownercheckbox_${idx}`)! as HTMLInputElement;
                                                        if (checkbox.checked) {
                                                            accountUpdate.owners.push(this.state.account.owners[idx]);
                                                            accountUpdateEmails.push(this.state.ownerEmails![idx]);
                                                        }
                                                    }
                                                    await AccountV1.updateAccount(accountUpdate);
                                                    this.setState({ 
                                                        account: accountUpdate, 
                                                        ownerEmails: accountUpdateEmails, 
                                                        editingPrivilege: false 
                                                    })
                                                } else {
                                                    this.setState({ editingPrivilege: false })
                                                }
                                            }} />
                                            <Button size="sm" hasIconOnly renderIcon={Close} iconDescription="Cancel edit" kind="ghost" tooltipAlignment="end" onClick={() => {
                                                this.setState({ editingPrivilege: false })
                                            }} />
                                        </>}
                                    </div>
                                    <div className="fields">
                                        <div><strong>Owners:</strong></div>
                                    </div>
                                    {(this.state.account && sortedEmails && <>
                                        {sortedEmails!.map((emailInfo) => (<>
                                            <div>
                                                {(this.state.editingPrivilege && <>
                                                    <Checkbox id={`ownercheckbox_${emailInfo.idx}`} labelText={emailInfo.value} defaultChecked={true} />
                                                </>) || emailInfo.value}
                                            </div>
                                        </>))}
                                    </>) || <>No owners for this account...</>}
                                </Tile>
                            </Column>
                        </Grid>
                        <div style={{ marginTop: "2rem" }} />
                        <Grid>
                            <Column sm={4} md={8} lg={12}>
                                {locationPanel}
                            </Column>
                        </Grid>
                        <div style={{ paddingTop: "4rem" }} />
                        {this.state.confirmDelete && <ModalConfirmDelete
                            open={!!this.state.confirmDelete}
                            confirmText={this.state.confirmDelete.name}
                            onClose={async () => {
                                this.setState({ confirmDelete: undefined });
                            }}
                            onDelete={async () => {
                                await LocationV1.deleteLocation(this.state.confirmDelete!.id);
                                this.refresh();
                            }}
                        />}
                        {this.state.addingPrivilege && <ModalBasic
                            open={this.state.addingPrivilege}
                            heading="Add owners" 
                            primaryButtonText="Add"
                            isDisabled={() => !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.state.addUserStr)}
                            onSubmit={async () => {
                                if (this.state.account) {
                                    let [id] = await UserV1.emailsToIds([this.state.addUserStr]);
                                    let accountUpdate = clone(this.state.account);
                                    let accountUpdateEmails = clone(this.state.ownerEmails) || [];
                                    accountUpdateEmails?.push(this.state.addUserStr);
                                    accountUpdate.owners = this.state.account.owners || [];
                                    accountUpdate.owners.push(id);
                                    await AccountV1.updateAccount(accountUpdate);
                                    this.setState({ account: accountUpdate, ownerEmails: accountUpdateEmails });
                                }
                            }}
                            onReset={async () => {
                                this.setState({ addUserStr: "" });
                            }}
                            onClose={async () => {
                                this.setState({ addingPrivilege: false })
                            }}
                        >
                            <TextInput 
                                data-modal-primary-focus
                                id="newOwner"
                                labelText="Owner email"
                                value={this.state.addUserStr}
                                onChange={(evt) => { this.setState({ addUserStr: evt.target.value }) }}
                            />
                            <div style={{marginTop: "1rem"}} />
                            <RadioButtonGroup legendText="Role" name="role" defaultSelected="owner" orientation="vertical">
                                <RadioButton labelText="Owner (can edit all account info)" value="owner" id="role-owner" />
                            </RadioButtonGroup>
                        </ModalBasic>}

                    </main>
                </>
                } />
            </Routes>
        )
    }
}

export default function Account() {
    const navigate = useNavigate();
    const params = useParams();
    return <AccountCls navigate={navigate} params={params} />;
}