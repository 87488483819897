import React from 'react';

import {
    Modal
} from "@carbon/react";

interface ModalBasicProps {
    open: boolean
    danger: boolean
    heading: string
    modalLabel?: string
    secondaryButtons?: any
    onReset?: () => Promise<void>
    onClose: () => Promise<void>
    onSubmit?: () => Promise<void>
    isDisabled: () => boolean
    primaryButtonText?: string
}

interface ModalBasicState {
}

export default class ModalBasic extends React.Component<ModalBasicProps & any, ModalBasicState> {
    state: ModalBasicState = {
    }

    componentDidMount(): void {
        this.props.onReset && this.props.onReset();
        setTimeout(() => {
            (document.querySelector(".cds--modal [data-modal-primary-focus]") as any)?.focus();
        }, 0);
    }

    componentDidUpdate(prevProps: Readonly<ModalBasicProps>, _prevState: Readonly<ModalBasicState>, _snapshot?: any): void {
        if (!prevProps.open && this.props.open) {
            this.props.onReset && this.props.onReset();
            setTimeout(() => {
                (document.querySelector(".cds--modal [data-modal-primary-focus]") as any)?.focus();
            }, 0);
        }
    }

    render() {
        const {
            open,
            heading,
            modalLabel,
            onReset,
            onClose,
            onSubmit,
            isDisabled,
            primaryButtonText,
            onDelete,
            ...rest
        } = this.props;
        return <Modal
            {...rest}
            open={this.props.open}
            aria-label={this.props.heading}
            modalHeading={this.props.heading}
            modalLabel={this.props.modalLabel}
            primaryButtonText={this.props.primaryButtonText || "Submit"}
            secondaryButtonText="Cancel"
            primaryButtonDisabled={ this.props.isDisabled() }
            onRequestClose={() => {
                this.props.onClose();
            }}
            onRequestSubmit={async () => {
                if (this.props.onSubmit) {
                    await this.props.onSubmit();
                }
                this.props.onClose();
            }}
            onSecondarySubmit={this.props.onClose}
            preventCloseOnClickOutside
        >
            <form onSubmit={async (evt) => { 
                evt.preventDefault();
                if (!this.props.isDisabled()) {
                    if (this.props.onSubmit) {
                        await this.props.onSubmit();
                    }
                    await this.props.onClose();
                }
            }}>
            {this.props.children}
            </form>
        </Modal>
    }
}