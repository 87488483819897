import React from 'react';

import { 
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbSkeleton,
    Column,
    Grid, 
    SkeletonText} from "@carbon/react";
import { Add, TrashCan, Edit } from '@carbon/react/icons';

import { AccountV1, IAccount } from '../../../../services/AccountV1';
import { CampV1, ICamp } from '../../../../services/CampV1';
import { CampFields } from '../NewCamp/NewCamp';
import SimpleTable from '../../../../components/SimpleTable';
import { AddEditSessionModal, AddEditSessionModalState } from '../Session/AddEditSessionModal';
import "./Camp.scss";
import { ISession, SessionV1 } from '../../../../services/SessionV1';
import { ModalConfirmDelete } from '../../../../components/ModalConfirmDelete';
import { Params, useParams } from 'react-router-dom';

interface CampProps {
    account?: IAccount
}

interface CampState {
    account?: IAccount
    camp?: ICamp
    campSessions?: ISession[]

    confirmDelete?: {
        id: string,
        name: string
    }

    editSession?: {
        id: string
    }

    dialog: {
        addSession: boolean
    }
}

class CampCls extends React.Component<CampProps & { params: Params<string> }, CampState> {
    state: CampState = {
        account: this.props.account,
        dialog: {
            addSession: false
        }
    }

    // constructor(props: CampProps) {
        // super(props);
        // const d = new Date();
        // d.setFullYear(d.getFullYear()-10);

        // this.state.dialog.addSession!.ageEnd = d.toLocaleDateString("en-US", {
        //     month: "numeric", day: "numeric", year: "numeric"
        // });

        // d.setFullYear(d.getFullYear()-1);
        // d.setDate(d.getDate()+1);
        // this.state.dialog.addSession!.ageStart = d.toLocaleDateString("en-US", {
        //     month: "numeric", day: "numeric", year: "numeric"
        // });
    // }

    componentDidMount() {
        (async () => {
            let camp = await CampV1.getCamp(this.props.params["campId"]!);
            let account = this.state.account || await AccountV1.getAccount(camp.account_id);
            this.setState({ account, camp })
            let campSessions = await SessionV1.getSessionsByCampId(camp.id);
            console.log(campSessions);
            this.setState({ campSessions });
        })();
    }

    componentWillUnmount() {
        // document.body.style.backgroundColor = "inherit";
    }

    async update(newCamp: ICamp) {
        newCamp.id = this.state.camp!.id;
        let camp = await CampV1.updateCamp(newCamp);
        this.setState({ camp });
    }

    async refresh() {
        this.setState({ campSessions: undefined })
        let campSessions = await SessionV1.getSessionsByCampId(this.state.camp!.id);
        this.setState({ campSessions });
    }

    render() {
        try {
            let editSession: ISession | undefined = undefined;
            if (this.state.campSessions && this.state.editSession) {
                let find = this.state.campSessions.filter(session => session.id === this.state.editSession!.id);
                if (find.length > 0) {
                    editSession = find[0];
                }
            }
            return (<>
                <div style={{ marginTop: "3rem" }} />
                <Grid>
                    <Column sm={4} md={8} lg={16}>
                        {(!this.state.account || !this.state.camp) && <BreadcrumbSkeleton /> }
                        {this.state.account && this.state.camp && <Breadcrumb>
                            <BreadcrumbItem href="/account/accounts">Accounts</BreadcrumbItem>
                            <BreadcrumbItem href={`/account/${this.state.account.id}`}>
                                {this.state.account.name}
                            </BreadcrumbItem>
                            <BreadcrumbItem href={`/account/${this.state.account.id}/camp/${this.state.camp.id}`} isCurrentPage>
                                {this.state.camp.name}
                            </BreadcrumbItem>
                        </Breadcrumb>}
                    </Column>
                </Grid>
                <main className="Camps">
                    <Grid>
                        <Column sm={4} md={6} lg={8}>
                            <h1>{!this.state.camp && <SkeletonText />}
                            {this.state.camp && this.state.camp.name}</h1>
                            {this.state.camp && <CampFields 
                                editing={false}
                                initialState={{
                                    name: this.state.camp!.name || "",
                                    urlname: this.state.camp!.urlname || "",
                                    type: this.state.camp!.type || "",
                                    address: this.state.camp.address || "",
                                    city: this.state.camp.city || "",
                                    state: this.state.camp.state || "",
                                    zip: this.state.camp.zip || "",
                                    shortdesc: this.state.camp.shortdesc || "",
                                    description: this.state.camp.description || ""
                                }}
                                submitLabel="Update account"
                                onSubmit={(newCamp: ICamp) => {
                                    this.update(newCamp);
                                }}
                            />}
                        </Column>
                    </Grid>
                    <div style={{marginTop: "0rem"}} />
                    <Grid>
                        <Column sm={4} md={8} lg={12}>
                            <h2>Sessions</h2>
                            <SimpleTable
                                noRecordsText="No sessions found"
                                headers={[
                                    { key: "label", header: "Name" },
                                    { key: "dateStart", header: "Session start" },
                                    { key: "dateEnd", header: "Session end" },
                                    { key: "actions", header: "Actions" }
                                ]}
                                actions={<>
                                <Button renderIcon={Add} onClick={() => {
                                    let dialog = JSON.parse(JSON.stringify(this.state.dialog));
                                    dialog.addSession = true;
                                    this.setState({ dialog });
                                }}>Add session</Button></>}
                                rows={this.state.campSessions}
                                cellMapper={(rowId, cellId, value) => {
                                    if (cellId === "actions") {
                                        return (<div style={{ marginLeft: "-1rem" }}>
                                            <Button
                                                kind="ghost"
                                                hasIconOnly
                                                iconDescription="Edit session"
                                                renderIcon={Edit}
                                                onClick={async (evt) => {
                                                    this.setState({
                                                        editSession: {
                                                            id: rowId
                                                        }
                                                    })
                                                    evt.stopPropagation();
                                                }}
                                            />
                                            <Button
                                                kind="ghost"
                                                hasIconOnly
                                                iconDescription="Delete"
                                                renderIcon={TrashCan}
                                                onClick={async (evt) => {
                                                    this.setState({
                                                        confirmDelete: {
                                                            id: rowId,
                                                            name: this.state.campSessions?.filter(camp => camp.id === rowId)[0].label!
                                                        }
                                                    })
                                                    evt.stopPropagation();
                                                }}
                                            />
                                        </div>);
                                    }
                                    return value;
                                }}
                            />
                        </Column>
                    </Grid>
                </main>
                <div role="region" aria-label="dialogs">
                    <AddEditSessionModal
                        show={this.state.dialog.addSession || !!this.state.editSession}
                        initialValue={editSession} 
                        onSession={async (session: AddEditSessionModalState) => {
                            let sess2 = session as any;
                            sess2.account_id = this.state.account!.id;
                            sess2.camp_id = this.state.camp!.id;
                            let campSessions : ISession[] = JSON.parse(JSON.stringify(this.state.campSessions));
                            if (this.state.editSession) {
                                sess2.id = this.state.editSession.id;
                                await SessionV1.updateSession(sess2);
                                campSessions = campSessions.map(session => session.id === sess2.id ? sess2 : session);
                            } else {
                                sess2.id = "";
                                let newSession = await SessionV1.createSession(sess2);
                                campSessions.push(newSession)
                            }
                            let dialog : { addSession: boolean } = JSON.parse(JSON.stringify(this.state.dialog));
                            dialog.addSession = false;
                            this.setState({ dialog, campSessions, editSession: undefined });
                        }}
                        onClose={async () => {
                            let dialog : { addSession: boolean } = JSON.parse(JSON.stringify(this.state.dialog));
                            dialog.addSession = false;
                            this.setState({ dialog, editSession: undefined });
                        }}
                    />
                    { this.state.confirmDelete && <ModalConfirmDelete 
                        modalLabel="Delete session"
                        open={!!this.state.confirmDelete}
                        confirmText={this.state.confirmDelete!.name}
                        onClose={async () => {
                            this.setState({ confirmDelete: undefined });
                        }}
                        onDelete={async () => {
                            await SessionV1.deleteSession(this.state.confirmDelete!.id);
                            await this.refresh();
                        }}
                    />}
                </div>
            </>
            )
        } catch (err) {
            console.log(err);
            return <>
            {JSON.stringify(err)}
            </>
        }
    }
}

export default function Camp(props: CampProps) {
    let params = useParams();
    return <CampCls params={params} {...props} />
}