import React from "react";
import { IPTLocation, PTReservationTime } from "../../services/LocationV1";
import ModalBasic from "../../../../components/ModalBasic";
import {TimeUtil, range12 } from "../../../../util/TimeUtil";
import { clone } from "../../../../util/MiscUtil";
import { 
    Button,
    Column,
    DatePicker,
    DatePickerInput,
    Grid,
    RadioButton,
    RadioButtonGroup,
    SelectItem,
    SkeletonText,
    TimePicker,
    TimePickerSelect
} from "@carbon/react";
import { TrashCan } from "@carbon/react/icons";

interface ModalState {
    dialogPicker: "day" | "weekly",
    dialogDOWPicker: string,
    dialogDay: string,
    dialogDOWs: string[],
    dialogTimes?: Array<{
        start: {
            timeStr: string,
            ampm: "AM"|"PM"
        },
        end: {
            timeStr: string,
            ampm: "AM"|"PM"
        }
    }>
}

export class LocationAvailabilityUpdateModal extends React.Component<{
    location?: IPTLocation,
    onLocation: (location: IPTLocation) => Promise<void>
    open: boolean
    onClose: () => Promise<void>
}, ModalState> {
    state: ModalState = {
        dialogPicker: "day",
        dialogDOWPicker: "",
        // mm/dd/yyyy
        dialogDay: "",
        dialogDOWs: []
    }

    componentDidUpdate(prevProps: Readonly<{ location?: IPTLocation; onLocation: (location: IPTLocation) => Promise<void>; open: boolean; onClose: () => Promise<void>; }>, prevState: Readonly<ModalState>, snapshot?: any): void {
        if (prevState.dialogPicker !== this.state.dialogPicker) {
            this.setState({dialogDOWPicker: "", dialogDay: "", dialogDOWs: [], dialogTimes: undefined});
        } else if (prevState.dialogDOWPicker !== this.state.dialogDOWPicker) {
            this.setState({dialogDay: "", dialogDOWs: [], dialogTimes: undefined});
        } else if (prevState.dialogDay !== this.state.dialogDay) {
            this.setState({dialogDOWPicker: "", dialogDOWs: [], dialogTimes: undefined});
        }
        if (prevState.dialogDOWPicker !== this.state.dialogDOWPicker || prevState.dialogDay !== this.state.dialogDay) {
            let dialogTimes: range12[] = []
            if (this.state.dialogDOWPicker !== "") {
                let updTimes: PTReservationTime[] = clone(this.props.location?.reservableSchedule?.[this.state.dialogDOWPicker] || []);
                dialogTimes = updTimes.map(timeInfo => TimeUtil.convR24toR12(timeInfo));
            } else if (this.state.dialogDay !== "") {
                let [month, day, year] = this.state.dialogDay.split("/");
                let dateStr = year+"/"+month+"/"+day;
                let updTimes = clone(this.props.location?.reservableDays?.[dateStr] ?? []);
                dialogTimes = updTimes.map(timeInfo => TimeUtil.convR24toR12(timeInfo));
            }
            dialogTimes.push({
                start: { timeStr: "", ampm: "AM" },
                end: { timeStr: "", ampm: "AM" }
            })
            this.setState({ dialogTimes });
        }
    }

    updateAddTime(idx: number, s: "start" | "end") {
        let chkdlg_time = document.getElementById(`chkdlg_time_${idx}_${s}`) as HTMLInputElement;
        let chkdlg_time_select = document.getElementById(`chkdlg_time_${idx}_${s}_select`) as HTMLInputElement;
        let dlgUpdate = clone(this.state.dialogTimes!);
        if (idx === dlgUpdate.length-1) {
            dlgUpdate.push({
                start: { timeStr: "", ampm: "AM" },
                end: { timeStr: "", ampm: "AM" }
            })
        }
        dlgUpdate[idx][s].timeStr = chkdlg_time.value;
        dlgUpdate[idx][s].ampm = chkdlg_time_select.value as "AM"|"PM";
        this.setState({ dialogTimes: dlgUpdate });
    }

    render() {
        return <ModalBasic
            open={this.props.open}
            heading="Update schedule"
            primaryButtonText="Update"
            isDisabled={() => false}
            onReset={async () => {
                this.setState({
                    dialogPicker: "day",
                    dialogDay: "",
                    dialogDOWPicker: "",
                    dialogDOWs: [],
                    dialogTimes: undefined
                })
            }}
            onSubmit={async () => {
                if (!this.state.dialogTimes) return;
                let locationUpdate = clone(this.props.location)!;
                let updTimes: Array<PTReservationTime> = this.state.dialogTimes
                    .filter(timeInfo => timeInfo.start.timeStr !== "" && timeInfo.end.timeStr !== "")
                    .map(
                        timeInfo => TimeUtil.convR12toR24(timeInfo)
                    );
                // Update here
                if (this.state.dialogPicker === "day") {
                    let parts = this.state.dialogDay.split("/");
                    let dateStr = parts[2]+"/"+parts[0]+"/"+parts[1];
                    locationUpdate.reservableDays = locationUpdate.reservableDays || {}
                    locationUpdate.reservableDays[dateStr] = updTimes;
                } else {
                    for (const dow of this.state.dialogDOWs.concat(this.state.dialogDOWPicker)) {
                        locationUpdate.reservableSchedule = locationUpdate.reservableSchedule || {}
                        locationUpdate.reservableSchedule[dow] = updTimes;
                    }
                }
                this.props.onLocation(locationUpdate);
            }}
            onClose={async () => {
                this.props.onClose();
            }}
            hasScrollingContent={true}
            size="md"
        >
            {/* Select what mode we're updating the schedule in */}
            {this.props.open && <>
                <RadioButtonGroup legendText="Schedule to update" name="dialog_avail_picker" defaultSelected="day" onChange={(newVal) => {
                    this.setState({ dialogPicker: newVal });
                }}>
                    <RadioButton labelText="One day" value="day" id="day" />
                    <RadioButton labelText="Regular weekly schedule" value="weekly" id="weekly" />
                </RadioButtonGroup>
                <div style={{ marginTop: "1rem" }} />
                {/* Update a specific day */}
                {this.state.dialogPicker === "day" && <>
                    <DatePicker datePickerType="single" value={this.state.dialogDay} onChange={(newVal) => {
                        this.setState({ dialogDay: (document.getElementById("dialog_avail_day_picker") as HTMLInputElement)!.value });
                    }} style={{ flexWrap: "wrap" }}>
                        <DatePickerInput placeholder="mm/dd/yyyy" labelText="Day to update" id="dialog_avail_day_picker" size="md" />
                    </DatePicker>
                </>}
                {/* Update regular schedule */}
                {this.state.dialogPicker === "weekly" && <>
                    <RadioButtonGroup legendText="Day to update" name="dialog_avail_dow_picker" onChange={(newVal) => {
                        this.setState({ dialogDOWPicker: newVal });
                    }} style={{ flexWrap: "wrap" }}>
                        <RadioButton labelText="Mon" value="1" id="dialog_avail_dow_Mon" />
                        <RadioButton labelText="Tue" value="2" id="dialog_avail_dow_Tue" />
                        <RadioButton labelText="Wed" value="3" id="dialog_avail_dow_Wed" />
                        <RadioButton labelText="Thu" value="4" id="dialog_avail_dow_Thu" />
                        <RadioButton labelText="Fri" value="5" id="dialog_avail_dow_Fri" />
                        <RadioButton labelText="Sat" value="6" id="dialog_avail_dow_Sat" />
                        <RadioButton labelText="Sun" value="0" id="dialog_avail_dow_Sun" />
                    </RadioButtonGroup>
                </>}
                {/* Update time for the selection above */}
                {(this.state.dialogDOWPicker !== "" || this.state.dialogDay !== "") && <>
                    <div style={{ marginTop: "1rem" }} />
                    {!this.state.dialogTimes && <SkeletonText />}
                    {this.state.dialogTimes?.map((time, idx) => (
                        <Grid key={`dlgTime_${idx}`}>
                            <Column sm={4} md={8} lg={12} style={{ 
                                marginTop: "1.25rem", 
                                marginBottom: (
                                    (time.start.timeStr.length > 0 && !TimeUtil.isHHMM12(time.start.timeStr))
                                    || (time.end.timeStr.length > 0 && !TimeUtil.isHHMM12(time.end.timeStr))
                                ) ? "-20px" : undefined
                            }}>
                                <div style={{display: "flex"}}>
                                    <div style={{flex: "0 0 13rem"}}>
                                    <TimePicker
                                        id={`chkdlg_time_${idx}_start`}
                                        labelText="Reservation start time"
                                        value={time.start.timeStr}
                                        invalid={time.start.timeStr.length > 0 && !TimeUtil.isHHMM12(time.start.timeStr)}
                                        onChange={() => {
                                            this.updateAddTime(idx, "start");
                                        }}
                                    >
                                        <TimePickerSelect
                                            id={`chkdlg_time_${idx}_start_select`}
                                            value={time.start.ampm}
                                            onChange={() => {
                                                this.updateAddTime(idx, "start");
                                            }}
                                        >
                                            <SelectItem value="AM" text="AM" />
                                            <SelectItem value="PM" text="PM" />
                                        </TimePickerSelect>
                                    </TimePicker>
                                    </div>
                                    <div style={{flex: "0 0 13rem"}}>
                                    <TimePicker
                                        id={`chkdlg_time_${idx}_end`}
                                        labelText="Reservation end time"
                                        value={time.end.timeStr}
                                        invalid={time.end.timeStr.length > 0 && !TimeUtil.isHHMM12(time.end.timeStr)}
                                        onChange={() => {
                                            this.updateAddTime(idx, "end");
                                        }}
                                    >
                                        <TimePickerSelect
                                            id={`chkdlg_time_${idx}_end_select`}
                                            value={time.end.ampm}
                                            onChange={() => {
                                                this.updateAddTime(idx, "end");
                                            }}
                                        >
                                            <SelectItem value="AM" text="AM" />
                                            <SelectItem value="PM" text="PM" />
                                        </TimePickerSelect>
                                    </TimePicker>
                                    </div>
                                    <div style={{flex: "1"}}>
                                        {idx < this.state.dialogTimes!.length-1 && <Button size="sm" hasIconOnly renderIcon={TrashCan} 
                                            iconDescription="Remove time" kind="ghost" tooltipAlignment="end" 
                                            onClick={() => {
                                                let dialogTimes = clone(this.state.dialogTimes)!;
                                                dialogTimes.splice(idx, 1);
                                                this.setState({dialogTimes});
                                            }}
                                            style={{marginTop: "1.5rem"}}
                                        />}
                                    </div>
                                </div>
                            </Column>
                        </Grid>
                    ))}
                </>}
            </>}
        </ModalBasic>
    }
}