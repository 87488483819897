import React from 'react';

import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Column,
    Grid,
    TextInput 
} from "@carbon/react";
import { Edit } from "@carbon/react/icons";
import "./NewAccount.scss";
import { AccountV1, IAccount } from '../../../../services/AccountV1';
import NumericPatternInput from '../../../../components/NumericPatternInput';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export interface AccountFieldsProps {
    initialState?: {
        name: string
        urlname: string
        phone: string
    }

    editing: boolean
    submitLabel: string
    onSubmit: (loc: IAccount) => void
    onCancel?: () => void
}

export interface AccountFieldsState {
    name: string,
    urlname: string,
    phone: string,
    conflictName: boolean
    conflictUrl: boolean
    invalid: boolean
    editing: boolean
}

export class AccountFields extends React.Component<AccountFieldsProps, AccountFieldsState> {
    state: AccountFieldsState = {
        name: this.props.initialState?.name ?? "",
        urlname: this.props.initialState?.urlname ?? "",
        phone: this.props.initialState?.phone ?? "",
        conflictName: false,
        conflictUrl: false,
        invalid: false,
        editing: this.props.editing
    }

    async onSubmit() {
        let newAccount : IAccount = {
            id: "",
            name: this.state.name,
            urlname: this.state.urlname,
            phone: this.state.phone
        }
        if (newAccount.name.length === 0 
            || newAccount.urlname.length === 0 
            || (newAccount.phone!.length > 0 && newAccount.phone!.length < 13)) 
        {
            this.setState({ invalid: true });
        } else {
            try {
                this.props.onSubmit(newAccount);
            } catch (err: any) {
                if (err.message === "name_conflict") {
                    this.setState({conflictName: true})
                } else if (err.message === "url_conflict") {
                    this.setState({conflictUrl: true})
                }
            }
        }
    }

    render() {
        if (!this.state.editing) {
            return <>
                <div className="bodyText">
                    <div><strong>Account name</strong>: {this.state.name}</div>
                    <div><strong>URL part</strong>: {this.state.urlname}</div>
                    <div><strong>Phone</strong>: {this.state.phone}</div>
                </div>
                <div style={{marginTop: "1rem"}} />
                <Button 
                    kind="tertiary"
                    renderIcon={Edit}
                    onClick={() => {
                        this.setState({editing: true});
                    }}>Edit account</Button>
            </>
        } else {
            let disableButton = this.state.name.length === 0 
                || this.state.urlname.length === 0
                || (this.state.phone.length > 0 && this.state.phone.length < 13);
            if (this.props.initialState) {
                disableButton = disableButton || 
                (this.state.name === this.props.initialState.name
                    && this.state.urlname === this.props.initialState.urlname
                    && this.state.phone === this.props.initialState.phone);
            }

            return <>
                <TextInput id="name" 
                    data-modal-primary-focus 
                    labelText="Account name" 
                    value={this.state.name} 
                    onChange={(evt) => {
                        this.setState({ conflictName: false, name: evt.target.value })
                    }}
                    invalid={this.state.conflictName || (this.state.invalid && this.state.name.length === 0)}
                    invalidText={this.state.conflictName ? "Account name conflicts with existing location" : "Account name is required"}
                />
                <div style={{marginTop: "1rem"}} />
                <TextInput id="urlname" 
                    labelText="URL part"
                    value={this.state.urlname} 
                    // helperText="URL part must only be letters or numbers"
                    onChange={(evt) => {
                        let cleanVal = evt.target.value.toLowerCase().replace(/[^a-z0-9-_]/g, "");
                        this.setState({ conflictUrl: false, urlname: cleanVal })
                    }}
                    invalid={this.state.conflictUrl || (this.state.invalid && this.state.urlname.length === 0)}
                    invalidText={this.state.conflictUrl ? "URL part conflicts with existing account" : "URL part is required"}
                />
                <div style={{marginTop: "1rem"}} />
                <NumericPatternInput
                    id="phone" 
                    labelText="Phone"
                    pattern="(000)000-0000"
                    helperText="(000)000-0000" 
                    initialValue={this.state.phone} 
                    onChange={(newVal: string) => {
                        this.setState({ phone: newVal });
                    }}
                    invalid={this.state.invalid && this.state.phone.length > 0 && this.state.phone.length < 13}
                    invalidText="Phone number is invalid"
                />
                <div style={{marginTop: "1rem"}} />
                <Button 
                    disabled={ disableButton }
                    onClick={() => {
                        this.onSubmit();
                        if (!this.props.editing) {
                            this.setState({editing: false});
                        }
                    }}>{this.props.submitLabel}</Button>
                {(this.props.onCancel || !this.props.editing) && <Button style={{marginLeft: "1rem"}} kind="secondary" onClick={() => {
                    this.props.onCancel?.()
                    if (!this.props.editing) {
                        this.setState({
                            name: this.props.initialState?.name ?? "",
                            urlname: this.props.initialState?.urlname ?? "",
                            phone: this.props.initialState?.phone ?? "",
                            editing: false
                        });
                    }
                }}>Cancel</Button>}
            </>;
        }
    }
}

interface NewAccountState {
}

class NewAccountCls extends React.Component<{ navigate: NavigateFunction }, NewAccountState> {
    async create(newAccount: IAccount) {
        await AccountV1.createAccount(newAccount);
        this.props.navigate("/account/accounts");
    }

    async cancel() {
        this.props.navigate("/account/accounts");
    }

    render() {
        return (
            <>
                <div style={{ marginTop: "3rem" }} />
                <Grid>
                    <Column sm={4} md={8} lg={16}>
                        <Breadcrumb>
                            <BreadcrumbItem href="/account/accounts">Accounts</BreadcrumbItem>
                            <BreadcrumbItem href="/account/new_account" isCurrentPage>
                                New account
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Column>
                </Grid>
                <div style={{ marginTop: "1rem" }} />
                <main className="NewAccount">
                    <Grid>
                        <Column sm={4} md={6} lg={8}>
                            <h1>New account</h1>
                            <div style={{marginTop: "2rem"}} />
                            <AccountFields 
                                editing={true}
                                submitLabel="Create account"
                                onSubmit={(newAccount: IAccount) => {
                                    this.create(newAccount);
                                }}
                                onCancel={() => {
                                    this.cancel();
                                }} />
                        </Column>
                    </Grid>
                </main>
            </>)
    }
}

export default function NewAccount() {
    let navigate = useNavigate();
    return <NewAccountCls navigate={navigate} />
}