import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import {
    CreateOrderData,
    CreateOrderActions,
    OnApproveData,
    OnApproveActions,
    INTENT,
    OrderResponseBody,
} from "@paypal/paypal-js";
import React from "react";
import { Button } from "@carbon/react";

interface PaypalButtonProps {
    intent: INTENT;
    amount: number;
    disabled?: boolean;
    onApprove?: (orderID: string, authDetails: OrderResponseBody) => Promise<void>;
}

interface PaypalButtonState {}

export class PaypalButton extends React.Component<
    PaypalButtonProps,
    PaypalButtonState
> {
    state: PaypalButtonState = {};

    async createOrder(
        _data: CreateOrderData,
        actions: CreateOrderActions
    ): Promise<string> {
        return await actions.order.create({
            intent: this.props.intent,
            purchase_units: [
                {
                    amount: {
                        value: "" + this.props.amount,
                    },
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING",
            },
        });
    }

    async onApprove(data: OnApproveData, actions: OnApproveActions) {
        const orderID = data.orderID;
        let authDetails: OrderResponseBody;
        if (this.props.intent === "CAPTURE") {
            authDetails = await actions.order!.capture();
        } else if (this.props.intent === "AUTHORIZE") {
            authDetails = await actions.order!.authorize();
        }
        await this.props.onApprove?.(orderID, authDetails!);
    }

    render() {
        const client_id =
            document.location.href.includes("partytaken.com") ||
            document.location.href.includes("camptaken.com")
                ? // Production id
                  "Af2p1LRvpYzKHQFu5BeXDQA1wvI2tFKKgmM_tVSmXHDjq8uLCwl6sRu1rACeB1D5aZhuoW_pLz9_JZ-h"
                : // Sandbox id
                  "ATeWUeSXSEPDGVypNASjQ-QmDLXgiA-lV0-1fkfGW8hELWEqHWjvDB29EBVMvwF_oUrI8vywcicb0thk";
        return (
            <>
                {this.props.disabled === true && (
                    <Button disabled={true}>
                        Pay with Paypal
                    </Button>
                )}
                {this.props.disabled !== true && (
                    <PayPalScriptProvider
                        options={{
                            clientId: client_id,
                            currency: "USD",
                            intent: this.props.intent.toLowerCase(),
                            disableFunding: "paylater",
                        }}
                    >
                        <PayPalButtons
                            createOrder={this.createOrder.bind(this)}
                            onApprove={this.onApprove.bind(this)}
                            style={{
                                label: "pay",
                            }}
                        />
                    </PayPalScriptProvider>
                )}
            </>
        );
    }
}
