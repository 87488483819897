import {
    Checkbox,
    Column,
    Grid,
    SkeletonText
} from "@carbon/react";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ReservationV1, { IReservation } from "../../services/ReservationV1";
import { TimeUtil } from "../../../../util/TimeUtil";
import { DateUtil } from "../../../../util/DateUtil";
import SimpleTable from "../../../../components/SimpleTable";
import { IUserBasic } from "../../../../models/IUser";

export interface ReservationsProps {
    user?: IUserBasic
}

interface ReservationsState {
    reservations?: Array<Partial<IReservation>>;
    showPast: boolean
}

class ReservationsCls extends React.Component<
    ReservationsProps & { navigate: NavigateFunction },
    ReservationsState
> {
    state: ReservationsState = {
        showPast: false
    };

    componentDidMount(): void {
        ReservationV1.getAll().then((reservations) => {
            this.setState({ reservations });
        });
    }

    render() {
        // Sort and organize the reservations
        let today = DateUtil.today();
        let reservations: Array<Partial<IReservation>>;
        let resInfos: Array<Partial<IReservation>> = [];
        let pastResInfos: Array<Partial<IReservation>> = [];
        if (this.state.reservations && this.state.reservations.length) {
            reservations = JSON.parse(JSON.stringify(this.state.reservations));
            reservations.sort((a, b) => {
                if (a.date !== b.date) {
                    return (
                        new Date(a.date!).getTime() -
                        new Date(b.date!).getTime()
                    );
                } else {
                    return TimeUtil.compare(a.time!.start, b.time!.start);
                }
            });
        
            for (const reservation of reservations) {
                if (reservation.status !== "CANCELLED" && new Date(reservation.date!).getTime() >= today.getTime()) {
                    resInfos.push(reservation);
                } else {
                    pastResInfos.push(reservation);
                }
            }
            resInfos.sort((a, b) => {
                return new Date(a.date!).getTime()-new Date(b.date!).getTime();
            })
            pastResInfos.sort((a, b) => {
                return new Date(b.date!).getTime()-new Date(a.date!).getTime();
            })
        }
        let mngInfo = this.props.user ? resInfos.filter(res => !res.editors?.includes(this.props.user!.email)) : [];
        resInfos = this.props.user ? resInfos.filter(res => res.editors?.includes(this.props.user!.email)) : [];
        return (
            <main className="Reservations">
                <Grid>
                    <Column sm={4} md={8} lg={16}>
                        <h1>Taken parties</h1>
                        {!this.state.reservations && (
                            <SkeletonText paragraph={true} />
                        )}
                        {this.state.reservations && (
                            <>
                                {resInfos.length === 0 && (
                                    <>No current parties taken</>
                                )}
                                {resInfos.length > 0 && (
                                    // <Tabs>
                                    //     <TabList>
                                    //         <Tab>Agenda</Tab>
                                    //         <Tab>Calendar</Tab>
                                    //     </TabList>
                                    //     <TabPanels>
                                    //         <TabPanel>
                                                <SimpleTable
                                                    noRecordsText=""
                                                    headers={[
                                                        { key: "date", header: "Date" },
                                                        { key: "timeStr", header: "Time" },
                                                        { key: "name", header: "Reservation Name" },
                                                        { key: "totalGuests", header: "Guests" },
                                                        { key: "status", header: "Status" }
                                                    ]}
                                                    rows={resInfos.map(res => {
                                                        (res as any).timeStr = TimeUtil.convR24toR12Str(res.time!);
                                                        (res as any).status = res.status!.charAt(0) + res.status!.substring(1).toLowerCase();
                                                        res.date = new Date(res.date!).toLocaleDateString();
                                                        return res;
                                                    }) as IReservation[]}
                                                    onClick={(rowId) => {
                                                        this.props.navigate(`/auth/reservation/${rowId}`);
                                                    }}
                                                />
                                    //         </TabPanel>
                                    //         <TabPanel></TabPanel>
                                    //     </TabPanels>
                                    // </Tabs>
                                )}
                            </>
                        )}
                        {pastResInfos.length > 0 && <div style={{marginTop: "2rem"}}>
                            <Checkbox id="showPast" labelText="Show past and cancelled parties" 
                                checked={this.state.showPast} onChange={(_: any, { checked }) => this.setState({showPast: checked})} />

                            { this.state.showPast && <>
                                <h2>Past parties</h2>
                                <div style={{marginTop: "1rem"}} />
                                {pastResInfos.length > 0 && (
                                        // <Tabs>
                                        //     <TabList>
                                        //         <Tab>Agenda</Tab>
                                        //         <Tab>Calendar</Tab>
                                        //     </TabList>
                                        //     <TabPanels>
                                        //         <TabPanel>
                                        <SimpleTable
                                            noRecordsText=""
                                            headers={[
                                                { key: "date", header: "Date" },
                                                { key: "timeStr", header: "Time" },
                                                { key: "name", header: "Reservation Name" },
                                                { key: "totalGuests", header: "Guests" },
                                                { key: "status", header: "Status" }
                                            ]}
                                            rows={pastResInfos.map(res => {
                                                (res as any).timeStr = TimeUtil.convR24toR12Str(res.time!);
                                                (res as any).status = res.status!.charAt(0) + res.status!.substring(1).toLowerCase();
                                                res.date = new Date(res.date!).toLocaleDateString();
                                                return res;
                                            }) as IReservation[]}
                                            onClick={(rowId) => {
                                                this.props.navigate(`/auth/reservation/${rowId}`);
                                            }}
                                        />
                                        //         </TabPanel>
                                        //         <TabPanel></TabPanel>
                                        //     </TabPanels>
                                        // </Tabs>
                                    )}
                            </>}
                        </div>}
                    </Column>
                </Grid>

                {mngInfo.length > 0 && <Grid>
                    <Column sm={4} md={8} lg={16}>
                        <h1>Managed parties</h1>
                        {this.state.reservations && (
                            <>
                                {mngInfo.length > 0 && (
                                    // <Tabs>
                                    //     <TabList>
                                    //         <Tab>Agenda</Tab>
                                    //         <Tab>Calendar</Tab>
                                    //     </TabList>
                                    //     <TabPanels>
                                    //         <TabPanel>
                                                <SimpleTable
                                                    noRecordsText=""
                                                    headers={[
                                                        { key: "date", header: "Date" },
                                                        { key: "timeStr", header: "Time" },
                                                        { key: "name", header: "Reservation Name" },
                                                        { key: "totalGuests", header: "Guests" },
                                                        { key: "status", header: "Status" }
                                                    ]}
                                                    rows={mngInfo.map(res => {
                                                        (res as any).timeStr = TimeUtil.convR24toR12Str(res.time!);
                                                        (res as any).status = res.status!.charAt(0) + res.status!.substring(1).toLowerCase();
                                                        res.date = new Date(res.date!).toLocaleDateString();
                                                        return res;
                                                    }) as IReservation[]}
                                                    onClick={(rowId) => {
                                                        this.props.navigate(`/auth/reservation/${rowId}`);
                                                    }}
                                                />
                                    //         </TabPanel>
                                    //         <TabPanel></TabPanel>
                                    //     </TabPanels>
                                    // </Tabs>
                                )}
                            </>
                        )}
                        {pastResInfos.length > 0 && <div style={{marginTop: "2rem"}}>
                            <Checkbox id="showPast" labelText="Show past and cancelled parties" 
                                checked={this.state.showPast} onChange={(_: any, { checked }) => this.setState({showPast: checked})} />

                            { this.state.showPast && <>
                                <h2>Past parties</h2>
                                <div style={{marginTop: "1rem"}} />
                                {pastResInfos.length > 0 && (
                                        // <Tabs>
                                        //     <TabList>
                                        //         <Tab>Agenda</Tab>
                                        //         <Tab>Calendar</Tab>
                                        //     </TabList>
                                        //     <TabPanels>
                                        //         <TabPanel>
                                        <SimpleTable
                                            noRecordsText=""
                                            headers={[
                                                { key: "date", header: "Date" },
                                                { key: "timeStr", header: "Time" },
                                                { key: "name", header: "Reservation Name" },
                                                { key: "totalGuests", header: "Guests" },
                                                { key: "status", header: "Status" }
                                            ]}
                                            rows={pastResInfos.map(res => {
                                                (res as any).timeStr = TimeUtil.convR24toR12Str(res.time!);
                                                (res as any).status = res.status!.charAt(0) + res.status!.substring(1).toLowerCase();
                                                res.date = new Date(res.date!).toLocaleDateString();
                                                return res;
                                            }) as IReservation[]}
                                            onClick={(rowId) => {
                                                this.props.navigate(`/auth/reservation/${rowId}`);
                                            }}
                                        />
                                        //         </TabPanel>
                                        //         <TabPanel></TabPanel>
                                        //     </TabPanels>
                                        // </Tabs>
                                    )}
                            </>}
                        </div>}
                    </Column>
                </Grid>}
            </main>
        );
    }
}

export function Reservations({ user } : { user?: IUserBasic }) {
    let navigate = useNavigate();
    return <ReservationsCls navigate={navigate} user={user} />;
}
