import React from "react";

import { Button } from "@carbon/react";
import { Add, TrashCan } from '@carbon/react/icons';
import ModalBasic from "./ModalBasic";
import { IReservation } from "../sites/partytaken/services/ReservationV1";
import SimpleTable from "./SimpleTable";
import { TextInput } from "@carbon/react";
import { isEmail } from "../util/MiscUtil";
import { Loading } from "@carbon/react";
import { IUserBasic } from "../models/IUser";

interface ModalPartyAccessProps {
    user?: IUserBasic;
    reservation?: Partial<IReservation>;
    open: boolean;
    danger?: boolean;
    heading: string;
    modalLabel?: string;
    onReset?: () => Promise<void>;
    onClose: () => Promise<void>;
    isDisabled: () => boolean;
    primaryButtonText?: string;
    onChange: (emails: string[]) => Promise<void>
}

interface ModalPartyAccessState {
    currentText: string,
    pendingChange: boolean
}

export default class ModalPartyAccess extends React.Component<ModalPartyAccessProps, ModalPartyAccessState> {
    state: ModalPartyAccessState = {
        currentText: "",
        pendingChange: false
    };

    componentDidUpdate(prevProps: Readonly<ModalPartyAccessProps>, prevState: Readonly<ModalPartyAccessState>, snapshot?: any): void {
        if (!prevProps.open && this.props.open) {
            this.setState({currentText: ""});
        }
    }

    async addEmail() {
        let emails = JSON.parse(JSON.stringify(this.props.reservation!.editors));
        if (!emails.some(email => email.toLowerCase() === this.state.currentText.toLowerCase())) {
            emails.push(this.state.currentText);
        }
        this.setState({ pendingChange: true })
        await this.props.onChange(emails);
        this.setState({ currentText: "", pendingChange: false })
    }

    async removeEmail(removeEmail: string) {
        let emails = JSON.parse(JSON.stringify(this.props.reservation!.editors));
        emails = emails.filter(email => email.toLowerCase() !== removeEmail.toLowerCase());
        this.setState({ pendingChange: true })
        await this.props.onChange(emails);
        this.setState({ currentText: "", pendingChange: false })
    }

    render() {
        if (this.state.pendingChange) {
            return <Loading />
        }
        let { onChange, ...rest } = this.props;
        if (!this.props.reservation) {
            return <></>;
        } else {
            let isEditor = this.props.user?.email 
                && this.props.reservation.editors?.includes(this.props.user?.email);
            return (
                <ModalBasic secondaryButtons={[]} {...rest}>
                    {isEditor && <div style={{display: "flex"}}>
                        <div style={{ flex: "1 0 auto"}}>
                            <TextInput id="addEditor" labelText="Add editor email" 
                                value={this.state.currentText}
                                onChange={(evt) => { this.setState({ currentText: evt.target.value }) }}
                                onKeyDown={async (evt: KeyboardEvent) => {
                                    if (evt.key === "Enter") {
                                        if (isEmail(this.state.currentText)) {
                                            this.addEmail();
                                        }
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                    }
                                }}                            
                            />
                        </div>
                        <div style={{ flex: "0 0 auto", marginLeft: "1rem", paddingTop: "1.5rem"}}>
                            <Button 
                                size="md" 
                                kind="secondary" 
                                hasIconOnly 
                                renderIcon={Add} 
                                iconDescription="Add" 
                                disabled={!isEmail(this.state.currentText)}
                                onClick={(evt) => {
                                    this.addEmail();
                                    evt.stopPropagation();
                                }} />
                        </div>
                    </div>}
                    <SimpleTable
                        hideSearch={true}
                        noRecordsText="No editors found"
                        headers={[
                            { key: "name", header: "Editor" },
                            { key: "actions", header: "Actions" },
                        ]}
                        
                        rows={this.props.reservation.editors?.map((editor, idx) => ({
                            id: editor, name: editor
                        }))}
                        onClick={(rowId) => {
                        }}
                        cellMapper={(rowId, cellId, value) => {
                            if (cellId === "actions" && rowId !== this.props.user?.email) {
                                if (isEditor) {
                                    return (
                                        <div style={{ marginLeft: "-1rem" }}>
                                            <Button
                                                kind="ghost"
                                                hasIconOnly
                                                iconDescription="Delete editor"
                                                renderIcon={TrashCan}
                                                onClick={async (evt) => {
                                                    this.removeEmail(rowId)
                                                }}
                                            />
                                        </div>
                                    );
                                } else {
                                    return <></>;
                                }
                            }
                            return value;
                        }}
                    />
                </ModalBasic>
            );
        }
    }
}
