import React from 'react';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbSkeleton,
    Button,
    Column,
    Grid,
    SkeletonText
} from "@carbon/react";
import { AccountV1, IAccount } from '../../../../services/AccountV1';
import { CampV1, ICamp } from '../../../../services/CampV1';
import { Add, TrashCan, FolderOpen } from '@carbon/react/icons';
import SimpleTable from '../../../../components/SimpleTable';
import {
    NavigateFunction,
    Params,
    Route,
    Routes,
    useNavigate,
    useParams
} from "react-router-dom";
import "./Account.scss";
import NewCamp from '../NewCamp/NewCamp';
import { AccountFields } from '../NewAccount/NewAccount';
import Camp from '../Camp/Camp';
import { ModalConfirmDelete } from '../../../../components/ModalConfirmDelete';
// import queryString from 'query-string';

interface AccountProps {
    navigate: NavigateFunction
    params: Params<string>
}

interface AccountState {
    account?: IAccount
    camps?: ICamp[]
    editing: boolean
    confirmDelete?: {
        id: string,
        name: string
    }
    confirmDeleteTxt: string
    selectedTab: number
}

class AccountCls extends React.Component<AccountProps, AccountState> {
    state: AccountState = {
        confirmDeleteTxt: "",
        editing: false,
        selectedTab: 0
    }

    componentDidMount() {
        // document.body.style.backgroundColor = "#eee";
        (async () => {
            let account = await AccountV1.getAccount(this.props.params["id"]!);
            this.setState({ account })
        })();
        this.refresh();
    }

    componentWillUnmount() {
        // document.body.style.backgroundColor = "inherit";
    }

    async refresh() {
        this.setState({ camps: undefined })
        let camps = await CampV1.getCampsByAccountId(this.props.params["id"]!);
        this.setState({ camps });
    }

    async onNewCamp(_camp: ICamp) {
        await this.refresh();
        // let camps = JSON.parse(JSON.stringify(this.state.camps));
        // camps.push(JSON.parse(JSON.stringify(camp)));
        // this.setState({ camps });
    }

    async update(newAccount: IAccount) {
        newAccount.id = this.state.account!.id;
        let account = await AccountV1.updateAccount(newAccount);
        this.setState({ account: account });
    }

    render() {
        let accountId = this.props.params["id"]!;

        // START campPanel
        let campPanel = (
            <SimpleTable
            
                noRecordsText="No camps found"
                headers={[
                    { key: "name", header: "Camp name" },
                    { key: "urlname", header: "URL part" },
                    { key: "actions", header: "Actions" }
                ]}
                actions={<>
                    <Button renderIcon={Add} onClick={() => {
                        this.props.navigate(`/account/${accountId}/new_camp`)
                    }}>New camp</Button></>}
                rows={this.state.camps}
                onClick={(rowId) => {
                    this.props.navigate(`/account/${this.state.account?.id}/camp/${rowId}`);
                }}
                cellMapper={(rowId, cellId, value) => {
                    if (cellId === "actions") {
                        return (<div style={{ marginLeft: "-1rem" }}>
                            <Button
                                kind="ghost"
                                hasIconOnly
                                iconDescription="Edit camp"
                                renderIcon={FolderOpen}
                                onClick={async () => {
                                    this.props.navigate(`/account/${this.state.account?.id}/camp/${rowId}`);
                                }}
                            />
                            <Button
                                kind="ghost"
                                hasIconOnly
                                iconDescription="Delete"
                                renderIcon={TrashCan}
                                onClick={async (evt) => {
                                    this.setState({
                                        confirmDelete: {
                                            id: rowId,
                                            name: this.state.camps?.filter(camp => camp.id === rowId)[0].name!
                                        }
                                    })
                                    evt.stopPropagation();
                                }}
                            />
                        </div>);
                    }
                    return value;
                }}
            />
        );
        // END campPanel

        return (
            <Routes>
                <Route path="/account/:accountId/camp/:campId" element={<Camp account={this.state.account} /> } />
                <Route path="/account/:id/new_camp" element={<NewCamp
                    account={this.state.account}
                    onNewCamp={(camp) => this.onNewCamp(camp)}
                />} />
                <Route path="/account/:id" element={<>
                    <div style={{ marginTop: "3rem" }} />
                    <Grid>
                        <Column sm={4} md={8} lg={16}>
                            {!this.state.account && <BreadcrumbSkeleton />}
                            {this.state.account && <Breadcrumb>
                                <BreadcrumbItem href="/account/accounts">Accounts</BreadcrumbItem>
                                <BreadcrumbItem href={`/account/${accountId}`} isCurrentPage>
                                    {this.state.account.name}
                                </BreadcrumbItem>
                            </Breadcrumb>}
                        </Column>
                    </Grid>
                    <main className="Camps">
                        <Grid>
                            <Column sm={4} md={8} lg={12}>
                                <h1>{!this.state.account && <SkeletonText />}
                                    {this.state.account && this.state.account.name}</h1>
                            </Column>
                        </Grid>
                        <Grid>
                            <Column sm={4} md={6} lg={8}>
                                {this.state.account && <AccountFields
                                    editing={this.state.editing}
                                    initialState={{
                                        name: this.state.account!.name || "",
                                        urlname: this.state.account!.urlname || "",
                                        phone: this.state.account!.phone || ""
                                    }}
                                    submitLabel="Update account"
                                    onSubmit={(newAccount: IAccount) => {
                                        this.update(newAccount);
                                    }}
                                />}
                            </Column>
                        </Grid>
                        <div style={{ marginTop: "3rem" }} />
                        <Grid>
                            <Column sm={4} md={8} lg={12}>
                                { campPanel }
                            </Column>
                        </Grid>
                        <div style={{ paddingTop: "4rem" }} />
                        { this.state.confirmDelete && <ModalConfirmDelete 
                            open={!!this.state.confirmDelete}
                            confirmText={this.state.confirmDelete!.name}
                            onClose={async () => {
                                this.setState({ confirmDelete: undefined });
                            }}
                            onDelete={async () => {
                                await CampV1.deleteCamp(this.state.confirmDelete!.id);
                                this.refresh();
                            }}
                        />}
                        
                    </main>
                </>
                } />
            </Routes>
        )
    }
}

export default function Account() {
    const navigate = useNavigate();
    const params = useParams();

    return <AccountCls navigate={navigate} params={params} />;
}