import { BasicResInfo } from "./ReservationV1"

export interface PTPackage {
    id: string,
    label: string,
    // Base cost in dollars
    base: {
        cost: number,
        deposit: number,
        // How many people included in the base package
        capacity: number
    }
    // Cost to add a person to this package (if available)
    addon?: {
        cost: number,
        deposit: number,
    }
    // If deposit, how many days before event must full payment be made
    days_before_full_pay: number,
    included_items: string[],
    addons: Array<{
        label: string,
        // Cost in dollars
        cost: number
    }>
}

export interface PTArea {
    id: string,
    label: string,
    sections: PTSection[],
    capacity: number
}

export interface PTSection {
    id: string,
    areaId: string,
    label: string,
    capacity: number,
    groupswith: string[]
}

export interface PTReservationTime {
    start: string, // hh:mm
    end: string // hh:mm
}

export interface IPTLocation {
    id: string,
    account_id: string,
    name: string,
    urlname: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    phone: string,
    email: string,
    days_in_advance: number,
    event_types: string[],
    managers: string[],
    staff: string[],
    // Reservable schedules
    reservableSchedule: {
        "0": PTReservationTime[],
        "1": PTReservationTime[],
        "2": PTReservationTime[],
        "3": PTReservationTime[],
        "4": PTReservationTime[],
        "5": PTReservationTime[],
        "6": PTReservationTime[]
    }
    reservableDays: {
        [yyyymmdd: string]: PTReservationTime[]
    }
    // Reservable areas
    areas: PTArea[]
    // Available packages
    packages: PTPackage[]
    searchdescription: string
    description: string
    locImages?: Array<{ desc: string, src: string }>,
}

export interface IImageDesc {
    desc: string,
    src: string // base64 string: data:image/jpeg;base64,/9j/
}

export class LocationV1 {
    static async getLocations(accountId: string) : Promise<IPTLocation[]> {
        let result = await fetch(`/api/pt/location?accountId=${accountId}`);
        return result.json();
    }

    static async getLocation(locationId: string) : Promise<IPTLocation> {
        let result = await fetch(`/api/pt/location/${locationId}`);
        return result.json();
    }

    static async getReservations(locationId: string, date: string) : Promise<BasicResInfo[]> {
        let result = await fetch(`/api/pt/location/${locationId}/reservations?date=${date}`);
        return result.json();
    }

    static async createLocation(newLocation: Partial<IPTLocation>) : Promise<IPTLocation> {
        let result = await fetch(`/api/pt/location`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newLocation)
        })
        if (result.status === 200) {
            return result.json();
        } else {
            let err = await result.json();
            throw new Error(err.name);
        }
    }

    static async updateLocation(updLocation: Partial<IPTLocation>) : Promise<IPTLocation> {
        let result = await fetch(`/api/pt/location`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(updLocation)
        })
        return result.json();
    }

    static async deleteLocation(id: string) {
        await fetch(`/api/pt/location/${id}`, {
            method: "DELETE"
        })
    }

    static async updatePhotos(locationId: string, locImages: IImageDesc[]) {
        let result = await fetch(`/api/pt/location/${locationId}/locImages`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(locImages)
        })
        return result.json();
    }
}