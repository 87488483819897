export function clone<T>(val: T) : T {
    if (!val) return val;
    return JSON.parse(JSON.stringify(val));
}

export function wait(ms: number) {
    return new Promise<void>(resolve => {
        setTimeout(resolve, ms);
    })
}

export function isEmail(email: string) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
}