import React, { ReactElement } from 'react';

import {
    DataTableSkeleton, 
    DataTable,
    Pagination
} from '@carbon/react';
import "./SimpleTable.scss";
import { TableToolbar } from '@carbon/react';
import { TableToolbarContent } from '@carbon/react';
import { TableToolbarSearch } from '@carbon/react';

const {
    Table,
    // TableBatchActions,
    // TableBatchAction,
    // TableSelectRow,
    // TableSelectAll,
    TableHead,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableRow} = DataTable;

interface SimpleTableProps<IRow extends { id: string }> {
    hideSearch?: boolean
    noRecordsText: string
    headers: Array<{ key: string, header: string }>
    actions?: ReactElement
    pageSizes?: number[]
    className?: string
    rows?: IRow[]
    onClick?: (rowId: string) => void
    cellMapper?: (rowId: string, cellId: string, value: string) => string | JSX.Element;
}

interface SimpleTableState {
    currentPage: number
    totalOnPage: number
}

export default class SimpleTable<IRow extends { id: string }> extends React.Component<SimpleTableProps<IRow>, SimpleTableState> {
    state: SimpleTableState = {
        currentPage: 1,
        totalOnPage: this.props.pageSizes ? this.props.pageSizes[0] : -1
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        let content = <></>;
        try {
            let headers = JSON.parse(JSON.stringify(this.props.headers));
            if (!this.props.rows) {
                content = <DataTableSkeleton
                        headers={(headers) || undefined}
                        showHeader={false}
                        showToolbar={false}
                        rowCount={3}
                        columnCount={headers.length} />
            } else if (this.props.rows.length === 0) {
                content = <>
                    <div>{ this.props.actions }</div>
                    <div style={{marginTop: "1rem"}}>{ this.props.noRecordsText }</div>
                </>
            } else {
                let pageRows = (rows: any[], currentPage: number, totalOnPage: number) => {
                    if (totalOnPage < 0) return rows;
                    const dataStartAt = (Number(currentPage) - 1) * totalOnPage;
                    let numPage = Math.ceil(rows.length / totalOnPage);
                    const dataEndAt = currentPage <= numPage ? dataStartAt + Number(totalOnPage) : rows.length - 1;

                    return rows.slice(dataStartAt, dataEndAt);
                };

                let rows: IRow[] = this.props.rows;
                content = (
                    <DataTable
                        rows={rows}
                        headers={headers}
                        isSortable>
                        {({
                            rows,
                            getTableProps,
                            getHeaderProps,
                            onInputChange
                        }: {
                            rows: any,
                            // headers: any,
                            onInputChange: any,
                            // selectedRows: any,
                            // getBatchActionProps: any,
                            getTableProps: any,
                            getHeaderProps: any
                            // getSelectionProps: any
                        }) => {
                            let pageData = pageRows(rows, this.state.currentPage, this.state.totalOnPage);
                            return (
                                <div>
                                    <TableContainer>
                                        <TableToolbar aria-label="data table toolbar">
                                            <TableToolbarContent>
                                                { this.props.hideSearch !== true && <TableToolbarSearch persistent defaultExpanded={true} onChange={onInputChange} placeholder='Search table' labelText='Search table' id='table_filter' />}
                                                { this.props.actions }
                                            </TableToolbarContent>
                                        </TableToolbar>

                                        <Table {...getTableProps()}>
                                            <colgroup>
                                                {headers.map((header) => (
                                                    <col key={header.key} className={`cell_${ header.key }`} />
                                                ))}
                                            </colgroup>
                                            <TableHead>
                                                <TableRow>
                                                    {headers.map((header: any) => {
                                                        return <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                                            {header.header}
                                                        </TableHeader>
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pageData.map((row) => {
                                                    return <TableRow className={this.props.onClick ? "rowClickable" : undefined} key={row.id} onClick={() => {
                                                        this.props.onClick && this.props.onClick(row.id);
                                                    }}>
                                                        {row.cells.map((cell: any) => {
                                                            if (this.props.cellMapper) {
                                                                let id = cell.id.split(":")[1];
                                                                return <TableCell key={cell.id}>
                                                                    {this.props.cellMapper(row.id, id, cell.value)}
                                                                </TableCell>
                                                            } else {
                                                                return <TableCell key={cell.id}>{cell.value}</TableCell>
                                                            }
                                                        })}
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {this.props.pageSizes && (() => {
                                        let paginationProps = {
                                            onChange: ({ page, pageSize }: { page: number, pageSize: number }) => {
                                                this.setState({
                                                    currentPage: page,
                                                    totalOnPage: pageSize
                                                })
                                            },
                                            pageSizes: this.props.pageSizes
                                        };
                                        return (
                                            <Pagination
                                                {...paginationProps}
                                                totalItems={rows.length}
                                                page={this.state.currentPage}
                                            />
                                        )
                                    })()}
                                </div>
                            )
                        }
                        }
                    </DataTable>
                );
            }
        } catch (err) {
            console.error(err);
        }
        return (
            <div className={this.props.className}>
                { content }
            </div>
        );
    }
}