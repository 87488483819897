import { OrderResponseBody } from "@paypal/paypal-js";
import { PTArea, PTPackage, PTSection } from "./LocationV1";

export type Status = "REQUESTED" | "CONFIRMED" | "PAID" | "COMPLETED" | "CANCELREQUESTED" | "CANCELLED" | "DECLINED" | "UNDEFINED";
export interface PTApiPaypalPayment {
    id: string,
    captureDetails?: {
        amount: {
            total: string
        },
        transaction_fee: {
            value: string
        },
        create_time: string
    },
    authDetails: {
        amount: {
            total: string
        }
    },
    refundDetails?: {
        amount: {
            total: string
        }
    }
}
export interface IReservation {
    id: string,
    status: Status,
    name: string,
    location_id: string,
    editors: string[],
    zip: string,
    date: string,
    time: {
        start: string,
        end: string
    },
    package: PTPackage,
    created: number,
    totalGuests: number,
    payments: PTApiPaypalPayment[],
    area: PTArea,
    sections: PTSection[],
    access: {
        staff: boolean,
        editor: boolean
    }
}

export interface BasicResInfo {
    id: string,
    name: string,
    sections: Array<{
        areaId: string,
        id: string
    }>,
    time: {
        start: string,
        end: string,
    }
    editors: string[],
    status: string,
    totalGuests: number
}

export type ReservationActions = "confirm" | "decline" | "cancel" | "requestcancel" | "declinecancel";

export default class ReservationV1 {
    public static async get(id: string): Promise<Partial<IReservation>> {
        let resp = await fetch(`/api/pt/reservation/${id}`);
        if (resp.status === 200) {
            return await resp.json();
        } else {
            throw new Error(await resp.json())
        }
    }

    public static async getAll() : Promise<Array<Partial<IReservation>>> {
        let resp = await fetch(`/api/pt/reservation`);
        return await resp.json();
    }
    
    public static async create(data: {
        location_id: string,
        date: string,
        timeStr: string,
        packageId: string,
        totalGuests: number,
        orderId: string,
        authDetails: OrderResponseBody
    }): Promise<Partial<IReservation>> {
        let resp = await fetch(`/api/pt/reservation`, {
            method: "POST",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return await resp.json();
    }

    public static async update(data: IReservation): Promise<Partial<IReservation>> {
        let resp = await fetch(`/api/pt/reservation`, {
            method: "PUT",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return await resp.json();
    }

    public static async updateBasic(data: BasicResInfo): Promise<Partial<IReservation>> {
        let resp = await fetch(`/api/pt/reservation?basic=true`, {
            method: "PUT",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return await resp.json();
    }

    public static async action(id: string, action: ReservationActions): Promise<void> {
        await fetch(`/api/pt/reservation/${id}/${action}`, {
            method: "PUT"
        });
    }
}