import React from 'react';

import {
    Button,
    Column,
    DatePicker,
    DatePickerInput,
    Grid,
    MultiSelect,
    TextInput
} from "@carbon/react";
import "./Home.scss";
import MyTheme from '../../../../components/MyTheme';
import { Helmet } from "react-helmet";
import { DateUtil } from '../../../../util/DateUtil';


interface MainState {
    zip: string,
    dateStr: string,
    event_types: string[]
}

export default class Main extends React.Component<{}, MainState> {
    state: MainState = {
        zip: "",
        dateStr: "01-01-2020",
        event_types: []
    }

    changeZip(event: any) {
        this.setState({ zip: event.target.value });
    };

    changeDate(pickerDateStr: string) {
        let newDate = new Date(pickerDateStr);
        let dateStr = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        dateStr += "/" + (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        dateStr += "/" + newDate.getFullYear() + "";
        this.setState({ dateStr });
    };

    constructor(props: any) {
        super(props);
        this.state.dateStr = DateUtil.todayStrPadded();
    }

    componentDidMount() {
        // document.body.style.backgroundColor = "hsl(181, 55%, 60%)";
        navigator.geolocation.getCurrentPosition((position) => {
            fetch(`/api/common/zip/fromgeo?lat=${position.coords.latitude}&long=${position.coords.longitude}`)
                .then((result) => {
                    return result.json();
                })
                .then((result) => {
                    if (result?.zipCode) {
                        this.setState({ zip: result.zipCode });
                    }
                })
        });
    }

    componentWillUnmount() {
        document.getElementById("root")!.style.backgroundColor = "#F4F4F4";
    }

    render() {
        document.getElementById("root")!.style.backgroundColor = "hsl(181, 55%, 60%)";
        let spacing = "1rem";

        return (<>
            <Helmet>
                <title>PartyTaken.com</title>
            </Helmet>
            <div>
                {/* <HomeNav siteKey="pt"></HomeNav> */}
                <main className="home">
                    <div className="cds--g90">
                        <Grid>
                            <Column
                                sm={4} md={{ span: 4, offset: 2 }} lg={{ span: 6, offset: 5 }}
                                style={{ marginTop: "4rem", marginBottom: "6rem" }}
                            >
                                <div className="calbal" style={{ textAlign: "center", marginBottom: "2rem" }}>
                                    <img src="../images/calbal.svg" alt="" />
                                </div>
                                <MyTheme siteKey="pt" theme="white" style={{ margin: "-1rem", padding: "1rem" }}>
                                    <div className="formHeading">
                                        <span>Find</span> a Party
                                    </div>
                                    <TextInput
                                        id="search-zip"
                                        labelText="Zip code (required)"
                                        value={this.state.zip}
                                        onChange={this.changeZip.bind(this)}
                                    />
                                    <div style={{ marginTop: spacing }} />
                                    <DatePicker
                                        datePickerType="single"
                                        value={this.state.dateStr}
                                        onChange={this.changeDate.bind(this)}
                                    >
                                        <DatePickerInput
                                            id="search-date"
                                            labelText="Date"
                                            // onClose={function noRefCheck(){}}
                                            // onOpen={function noRefCheck(){}}
                                            helperText="mm/dd/yyyy"
                                        />
                                    </DatePicker>
                                    <div style={{ marginTop: spacing }} />
                                    <MultiSelect
                                        label={this.state.event_types.join(", ")}
                                        id="event_type_selector"
                                        titleText="Type of event"
                                        initialSelectedItems={this.state.event_types}
                                        items={[
                                            "Kid party"
                                            , "Adult party"
                                            , "Wedding"
                                            , "Anniversary"
                                            , "Work party"
                                            , "Other"
                                        ]}
                                        itemToString={item => item ?? ''}
                                        selectionFeedback="fixed"
                                        // helperText="Types of events that would be appropriate at this location"
                                        onChange={(evt: { selectedItems: string[] }) => {
                                            this.setState({ event_types: evt.selectedItems });
                                        }}
                                    />
                                    <div style={{ marginTop: "1.5rem" }} />
                                    <Button
                                        href={`/partyplaces?zip=${this.state.zip}&date=${this.state.dateStr}&type=${encodeURIComponent(this.state.event_types.join(","))}`}
                                        disabled={!/\d{5}/.test(this.state.zip)}
                                    >Search</Button>
                                </MyTheme>
                            </Column>
                        </Grid>
                        <img alt="" className="background" src={"../../images/lights.png"} />
                    </div>
                </main>
            </div>
        </>)
    }
}